.app-info {
    margin: 40px;
}

.main-heading-container {
    display: flex;
    align-items: center;
}

.header-icon {
    margin-right: 8px;
}

.main-heading {
    font-family: "ABBVoice-bold";
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #000000;
    margin-right: 8px;
}

.sub-heading-container h3 {
    font-family: "ABBVoice";
    color: #696969;
}

.installed-edge-list-wrapper {
    width: calc(100% - 8px);
    background-color: white;
    margin-right: 8px;
    padding: 32px;
}

.installed-edge-list-container {
    padding: 8px;
}

.app-details {
    width: calc(80% - 8px);
    margin-left: 8px;
}

.app-card:not(:first-child) {
    margin-top: 8px;
}

.list-item-icon {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-right: 16px;
}

.list-item-icon .indicator {
    width: 20px;
    height: 3px;
    background-color: #f03040;
    margin-bottom: 8px;
}

.list-item .list-item-main-heading {
    line-height: 1em;
    margin-bottom: 4px;
    text-transform: uppercase;
    font-family: ABBVoice-bold;
}

.list-item .list-item-sub-heading {
    line-height: 1.1em;
    color: #696969;
    font-size: 14px;
}

.list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #dbdbdb;
}

.app-info .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 8px 0;
}

.app-info .column {
    background-color: white;
}