.license-dialog {
  z-index: 1000;
}

.views-container {
  z-index: 1000;
}

.license-content {
  background-color: #fff;
  padding: 0px 25px;
  min-height: 94%;
}

.license-content .content {
  max-height: calc(100vh - 9em);
  max-width: calc(100vw - 6em);
  overflow: auto;
}

.Licence-content .font-italic {
  font-style: italic;
}

.license-wrap {
  position: absolute;
  background-color: #fff;
  /* left: 0; */
}

.license-container {
  min-height: 92vh;
  /* overflow: auto; */
  max-height: calc(100vh - 188px);
  font-family: ABBvoice;
  padding: 0px 15px;
  font-size: 13px;
}

.license-content {}

.license-container .license-information {
  font-weight: 600;
  font-size: 13px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  padding-top: 10px;
}

.dark-view-container .license-content {
  background-color: #333;
  color: #dbdbdb;
}

.dark-view-container .notes-heading {
  background-color: #333;
  color: #dbdbdb;
}

.license-container pre {
  word-wrap: normal;
  white-space: pre-line;
  word-break: normal;
}