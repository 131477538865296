.modal-submit-cancel {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px 10px 0px;
  margin-top: 20px;
}
.modal-submit-cancel button {
  margin-left: 10px;
}
.new-edge-location {
  margin-top: 20px;
}
.create-edge-modal {
  width: 600px;
}
