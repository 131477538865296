.each-list-icons-container {
  display: flex;
}

.each-list-wrap {
  display: flex;
  border-bottom: 1px solid #bababa;
  cursor: pointer;
}

.each-list-wrap:hover {
  background-color: #ebebeb;
}

.edge-location {
  color: #696969;
}

.each-list-content {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  padding: 8px 15px;
  justify-content: space-between;
}

/* .edge-section-container {
    padding-right: 40px;
  }
  .edge-section-content {
    overflow: auto;
    max-height: calc(100vh - 25em);
  } */
/* .add-button-wrap {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
    padding-right: 45px;
  } */
/* .application-toggle-wrap {
    margin-top: 30px;
  }
  .application-toggle-wrap ul li {
    width: 50%;
  } */
.application-state {
  color: #696969;
}

.each-list-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.application-section-content {
  overflow: auto;
  max-height: calc(100vh - 25em);
}

.application-view {
  display: flex;
  margin-top: 8px !important;
}

.application-view .each-app-section {
  width: calc(100% - 8px);
  background-color: #fff;
  padding: 16px;
}

.application-view .each-app-section:not(:last-child) {
  margin-right: 16px;
}

.separator {
  width: 10%;
}

.application-view {
  min-height: calc(100vh - 16em);
  margin-top: 20px;
}

.application-section-container {
  margin-top: 20px;
}

.save-export-button-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.save-export-button-wrap button {
  margin-left: 10px;
}

.browse-top-left {
  display: flex;
  /* padding-left: 10px; */
  /* margin-top: 10px; */
  /* flex: 1; */
  flex-wrap: wrap;
}

.bread-crumb-devider {
  margin-left: 5px;
  margin-right: 5px;
}

.dark-view-container .bread-crumb-devider {
  color: #dbdbdb;
}

.bread-crumb-cell {
  color: #696969;
  font-weight: 800;
  font-family: ABBVoice-bold;
}

.bread-crumb-server {
  color: #36f;
  cursor: pointer;
}

.bread-crumb-server:hover {
  text-decoration: underline;
}

.edge-platform-name {
  color: #1f1f1f;
  text-transform: capitalize;
}

.information-edge {
  margin-top: -8px;
  margin-left: 10px;
  margin-right: 10px;
}

.update-edge-section {
  display: flex;
}

.update-icon-wrap {
  margin-top: -8px;
}

.edge-details-content {
  min-height: 100px;
}

.main-heading {
  font-family: ABBVoice-bold;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  margin-right: 8px;
}

.section-header {
  text-transform: uppercase;
  /* font-weight: 700;
  font-size: 14px; */
  line-height: 1.5rem;
  font-family: 'ABBVoice-medium';
}


.screenTabs{
  display: flex;
  flex-direction: 'row';
  column-gap: 10px;
  font-size: 20px;
  font-weight: 100;
  margin-top: 20px;
}


.viewOptions:hover{
  border-bottom-color: #d9d9d9;
  color: #585858;

}

.viewOptions{
  transition: 0.2s;
  cursor: pointer;
  padding: 0px 10px;
  height: 40px;
  color: #868686;
  border-bottom: 5px solid transparent;
}

.viewOptions.active{
 color:#3366FF;
border-bottom-color: #3366FF;
}

.seprator{
  border-left:3px solid #2c2c2c;
  height: 70%;
  margin-top:5px;
}

.softwareScreen{
  background-color: #ffffff;

  padding: 10px;
  margin-top: 10px;
  overflow: auto;
 
  
}

.softwareTable {
  box-shadow: 0px 0px 10px -5px #000;

  width: 800px;
  margin-top: 20px;
  border-collapse:collapse;
}

.softwareTable th {
  text-align: left;
  background-color: #000;
  color: #fff;
  height: 40px;
  padding: 10px;
}

.softwareTable td {
  text-align: left;
  height: 30px;
  padding: 10px;
}
.softwareTable tr:nth-child(odd) {
  background-color: #d2d2d2
}



.DownloadLinkContainer{
  
  background: #e3e3e3;
  border-radius: 5px;
  padding: 20px;
}

.edge-info-icon div{
  margin-top: -9px;
}

.edge-info-icon{
  height: 30px !important;
}