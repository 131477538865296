.sorted-column {
  border-bottom: 4px solid #2e92fa !important;
}
td {
  overflow: unset !important;
  white-space: unset !important;
  text-overflow: unset !important;
  word-wrap: break-word;
  word-break: break-word;
}
.actions-cell {
  text-align: right;
  width: 50px;
}
.actions-header-column {
  width: 130px;
}
.click-class {
  cursor: pointer;
}
.checkBox-header-column {
  width: 30px;
}
.check-box-cell {
  width: 30px;
  padding: 0px !important;
}
.header-content-wrap {
  display: flex;
  justify-content: space-between;
}
.sortable-header {
  cursor: pointer;
}
.loading-container {
  min-height: 100px;
  display: flex;
  justify-content: center;
  margin-left: 50%;
  flex: 1;
  width: 100%;
}
.no-data-container {
  text-align: center !important;
}
.action-icons-container {
  display: flex;
}
.table-action-icon {
  cursor: pointer;
}
.action-icon-wrap {
  width: 40px;
}
.action-cell .action-disabled {
  pointer-events: none;
  opacity: 0.4;
}
