.edges-wrap {
  padding: 40px;
  min-height: calc(100vh - 155px);
  font-family: ABBVoice-medium !important;
  margin-top: -8px;
  margin-bottom: -8px; 
}
.edges-header-label {
  font-family: ABBVoice-bold;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  margin-right: 8px;
}
.edges-header-container {
  padding: 5px 0px;
  display: flex;
  justify-content: right;
  border-radius: 3px;
}
.edges-view-wrap {
  overflow: auto; 
  min-height: calc(100vh - 15em); 
  margin-top: 5px;
  max-height: calc(100vh - 25em);
}

.edges-container {
  background-color: #fff;
  padding: 40px 40px 10px 40px;
  margin-top: 20px;
}
.edges-body {
  overflow: auto;
  min-height: calc(100vh - 25em);
  margin-top: 25px;
  max-height: calc(100vh - 25em);
  margin-bottom: 16px;
}
.edges-body thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
