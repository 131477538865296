.licence-container {
    padding: 32px;
    /* Uncomment this when releasenote api is available.*/ 
    /* width: calc(45% - 72px); */
    width: calc(100% - 72px);
    height: calc(67vh - 64px);
}

.app-license-content {
    height: calc(100% - 70px);
    overflow-x: auto;
    color: #1f1f1f;
}

.app-license-content p {
    margin-bottom: 8px;
}

.app-license-content h4 {
    font-family: ABBVoice-bold;
}

.app-license-content strong {
    font-family: ABBVoice-bold;
    color: #1f1f1f;
}

.app-licence-footer {
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media only screen and (max-width: 961px) {
    .licence-container {
        width: calc(100% - 72px);
    }
}