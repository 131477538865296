.submit-cancel-warning-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.submit-cancel-warning-wrap button {
  margin-left: 10px;
}
.warning-dialog-modal .ABB_CommonUX_Dialog__content {
  padding: 16px 25px 8px 32px !important;
}
